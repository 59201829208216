import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide, TextField, Typography
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React, {FormEvent, forwardRef, useEffect, useState} from 'react';
import IOpenable from '@/types/interfaces/IOpenable';
import Loading from '@/components/Loading';
import { useStores } from '@/stores';
import {PER_PAGE_OPTIONS, SELECT_ALL} from "@/types/dto/PaginationRequest.dto";
import * as signalR from '@microsoft/signalr';
import {te} from "date-fns/locale";

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AllocationDialog = ({ open, setOpen }: IOpenable) => {
  const { userStore, courseStore, projectStore } = useStores();
  const [loading, setLoading] =  useState(false);
  const [showResult, setShowResult] =  useState(false);
  const [showOK, setShowOK] =  useState(false);
  const [connection, setConnection] = useState<signalR.HubConnection>();
  const [message, setMessage] = useState<string>('');
  const [tempMsg, setTempMsg] = useState<string>('Tildelingsprosessen skal ta litt tid. Du kan jobbe med andre ting imens.');
  let temp = 0;
  useEffect(() => {
    configSocket();
  }, []);

  useEffect(() => {
    if (connection) {
      connection.start()
          .then(result => {
            connection.on('publishLogMessage', msg => {
              setTempMsg(msg);
            });
          })
    }
  }, [connection]);

  useEffect(() => {
    if(temp > 39){
      setMessage(tempMsg);
      temp = 0;
    }
    else {
      setMessage(message + '\n' + tempMsg);
    }
    console.log(temp);

    temp++;
  }, [tempMsg]);

  const configSocket = async () => {
    const socketConnection =new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Information)
        .withUrl(process.env.REACT_APP_SERVER_BASE_URL+'/publishLogMessage', {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .build();
    setConnection(socketConnection);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleClose();
    courseStore.getList({
      skip: 0,
      take: +PER_PAGE_OPTIONS[0],
      search: '',
      projectId: projectStore.currentProject?.id+'',
      studySubjectId: SELECT_ALL,
      externalSchoolId: SELECT_ALL,
      status: SELECT_ALL,
    });
  };
  const fetchAllocation = async ( ) => {
    setShowOK(true);
    setLoading(true);
    await userStore.allocationAllStudent();
    setLoading(false);
    setShowResult(true);
  };
  const handleClose = () => {
    setOpen(false);
    setLoading(false)
    setShowResult(false);
    setShowOK(false);
  };


  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>Tildeling</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!showResult && <TextField fullWidth  multiline
                                       minRows={40}
                                       maxRows={40}
                                       value={message}/>}
          </DialogContentText>
          {showResult && <Typography variant="h6">
            Tildelingsresultat:
            <br/>- Tildelt med 1. prioritert: {userStore.allocationResult.firstPriority}
            <br/>- Tildelt med 2. prioritert: {userStore.allocationResult.secondPriority}
            <br/>- Tildelt med 3. prioritert: {userStore.allocationResult.thirdPriority}
          </Typography>}
        </DialogContent>
        <DialogActions>
          {(!showOK) && <Button variant="text" onClick={() => handleClose()}>
            Avbryt
          </Button>}
          {!loading && !showOK && <Button type="submit" onClick={() => fetchAllocation()}>
            Tildel
          </Button>}
          {showOK && <Button type="submit" disabled={!showResult}>OK</Button>}
        </DialogActions>
      </form>
    </Dialog>

  );
};

export default AllocationDialog;
